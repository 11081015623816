import { useCallback, useEffect, useMemo, useState } from 'react';
import { fromPairs, clone, compact, isEqual, merge, last, max, min, inRange } from 'lodash';
import axios from 'axios';

import {
  settingsOutline,
  add as addIcon,
  chevronBackOutline,
  chevronForwardOutline,
  checkmarkCircle,
  ellipseOutline,
  closeCircleOutline,
  checkmarkCircleOutline,
} from 'ionicons/icons';
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonContent,
  IonButton,
  IonIcon,
  IonLabel,
  IonFooter,
  IonItem,
  IonList,
  IonModal,
  IonInput,
  IonTextarea,
} from '@ionic/react';
import './App.css';
import { useQuery } from '@tanstack/react-query';
import useLocalStorageState from 'use-local-storage-state';

type Batch = {
  id: string;
  startsAt: number | undefined;
  endsAt?: number;
};

type ExAttendee = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  checkedInAt: string | null;
  customFields: Record<string, { value: string }>;
};

type RecogRecord = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  checkedInTime?: number | null;
  stateNoteBatchId?: string;
  stateNoteState?: boolean;
};

const apiKey = 'user:ea51c5ca-5338-4930-b962-74847d1f81f9:dac185f284c613e26e38a8a03bb21c5dc376';
const eventId = '7f166edd-cb2f-451a-ba9a-a34a97c39fd4';
const recogCustomFieldId = 'ffb826af-efb4-4659-85a6-2f254ef55626';
const refetchInterval = 10000;

function getDocBaseUrl(docUrl: string) {
  const match = /^(https?:\/\/[^/]+(?:\/o\/[^/]+)?)\/(?:doc\/([^/?#]+)|([^/?#]{12,}))/.exec(docUrl);
  const server = match?.[1] ?? '';
  const docId = match?.[2] ?? match?.[3] ?? '';
  return `${server}/api/docs/${docId}/`;
}

const grist = axios.create({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  baseURL: getDocBaseUrl('https://grist.ndjc.org.hk/o/docs/pd8auPonySKT/JCI-North-District'),
  headers: {
    Authorization: 'Bearer 7b3f6c9c91b87696488d6ac423f8ed7f06815724',
  },
});
const attendeesApi = axios.create({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  baseURL: 'https://e-recog-api.ndjc.org.hk',
});

function generateBatchId() {
  return new Date().getTime().toString();
}

function getNextNoteState(prev: boolean | undefined) {
  return prev === undefined ? true : prev ? false : undefined;
}

function isWithinTimeRange(
  subjectTime: number,
  startTime: number | undefined,
  endTime: number | undefined,
) {
  const numericSubject = subjectTime;
  const numericStart = startTime ?? -Infinity;
  const numericEnd = endTime ?? Infinity;
  return inRange(numericSubject, numericStart, numericEnd);
}

function SettingModal({ isOpened, onDismiss }: { isOpened: boolean; onDismiss: () => void }) {
  const handleResetClick = useCallback(() => {
    // eslint-disable-next-line no-alert
    if (confirm('Are you sure to reset and clear app data? This is not reversible.')) {
      localStorage.clear();
      location.reload();
    }
  }, []);

  return (
    <IonModal isOpen={isOpened}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Settings</IonTitle>
          <IonButtons slot="end">
            <IonButton
              color="primary"
              onClick={() => {
                onDismiss();
              }}
            >
              Done
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonList>
          <IonItem>
            <IonTextarea
              label="App Config"
              rows={20}
              placeholder="Paste JSON config here: { ... }"
            ></IonTextarea>
          </IonItem>
          <IonItem button color="danger" onClick={handleResetClick}>
            Reset And Clear Data
          </IonItem>
        </IonList>
      </IonContent>
    </IonModal>
  );
}

function App() {
  const [batches, setBatches] = useLocalStorageState<{
    currentId?: string;
    map: Record<string, Batch>;
    keys: string[];
  }>('eRecog:batches', {
    defaultValue: {
      map: {},
      keys: [],
    },
  });

  const [recogRecordMap, setRecogRecordMap] = useLocalStorageState<Record<string, RecogRecord>>(
    'eRecog:recogRecordMap',
    { defaultValue: {} },
  );
  const [recogRecordNames, setRecogRecordNames] = useLocalStorageState<string[]>(
    'eRecog:recogRecordNames',
    { defaultValue: [] },
  );

  const newBatch = useCallback((isInit?: boolean) => {
    const newBatchId = generateBatchId();
    setBatches(prev => {
      const { currentId: prevCurrentId, map: prevMap, keys: prevKeys } = prev;
      if (isInit && prevKeys.length > 0) return prev;

      const updates: Record<string, Batch> = {};

      // End last batch
      const lastBatchId = last(prevKeys);
      if (lastBatchId) {
        const prevLastBatchRecord = prevMap[lastBatchId]!;
        updates[lastBatchId] = {
          ...prevLastBatchRecord,
          endsAt: new Date().getTime(),
        };
      }

      // Generate new batch
      updates[newBatchId] = {
        id: newBatchId,
        startsAt: new Date().getTime(),
      };

      return {
        currentId: prevCurrentId ?? newBatchId,
        map: merge(clone(prevMap), updates),
        keys: [...prevKeys, newBatchId],
      };
    });
    return newBatchId;
  }, []);

  useEffect(() => {
    newBatch(true);
  }, []);

  const recogListQuery = useQuery({
    enabled: true,
    queryKey: ['recogListQuery'],
    refetchInterval,
    refetchOnWindowFocus: false,
    async queryFn() {
      const recogListReq = await grist.get('tables/RecognitionList/records?sort=group,order');
      const typedRecords = recogListReq.data.records as Array<{ fields: { chName: string } }>;
      const names = typedRecords.map(x => x.fields.chName);
      return names;
    },
  });

  useEffect(() => {
    if (!recogListQuery.isSuccess) return;

    setRecogRecordNames(recogListQuery.data ?? []);
  }, [recogListQuery.data]);

  /* @ts-expect-error: testing */
  window.stateProbe ??= {};
  /* @ts-expect-error: testing */
  window.stateProbe.batches = batches;
  /* @ts-expect-error: testing */
  window.stateProbe.recogRecordMap = recogRecordMap;
  /* @ts-expect-error: testing */
  window.stateProbe.recogRecordNames = recogRecordNames;

  const attendeesQuery = useQuery({
    enabled: true,
    queryKey: ['attendeesQuery'],
    refetchInterval,
    refetchOnWindowFocus: false,
    async queryFn() {
      const attendeeListReq = await attendeesApi.get(
        `/attendees?apiKey=${encodeURIComponent(apiKey)}&eventId=${encodeURIComponent(eventId)}`,
      );
      const attendeeList = attendeeListReq.data.data as ExAttendee[];
      return attendeeList;
    },
  });

  useEffect(() => {
    const attendeeRecords = attendeesQuery.data ?? [];
    const recogRecordMapUpdates = fromPairs(
      attendeeRecords.map(attendeeRecord => {
        const { customFields, checkedInAt } = attendeeRecord;
        const recogName = customFields[recogCustomFieldId]?.value ?? '';
        const recogRecordPartial = {
          checkedInTime: checkedInAt ? new Date(checkedInAt).getTime() : null,
        };
        return [recogName, recogRecordPartial];
      }),
    );

    setRecogRecordMap(prev => {
      const next = clone(prev);
      merge(next, recogRecordMapUpdates);
      return isEqual(prev, next) ? prev : next;
    });
  }, attendeesQuery.data);

  const checkedInTimeByName = useMemo<Record<string, Date | undefined>>(() => {
    if (!attendeesQuery.data) return {};

    return fromPairs(
      compact(
        attendeesQuery.data.map(record => {
          const recogName = record.customFields[recogCustomFieldId]?.value ?? '';
          if (!recogName) return undefined;

          const checkedInTime = record.checkedInAt ? new Date(record.checkedInAt) : undefined;
          return [recogName, checkedInTime];
        }),
      ),
    );
  }, [attendeesQuery.data]);

  const onStateNoteToggleClickHandlers = useMemo(
    () =>
      fromPairs(
        recogRecordNames.map(name => [
          name,
          () => {
            setRecogRecordMap(prevMap => {
              const prevRec = prevMap[name] ?? {};
              const nextNoteState = getNextNoteState(prevRec.stateNoteState);
              const nextRec = clone(prevRec);
              nextRec.stateNoteBatchId = batches.currentId;
              nextRec.stateNoteState = nextNoteState;
              return { ...prevMap, [name]: nextRec };
            });
          },
        ]),
      ),
    [recogRecordNames, batches.currentId],
  );

  const hasPrevBatch = useMemo(() => batches.keys.indexOf(batches.currentId!) > 0, [batches]);

  const hasNextBatch = useMemo(
    () => batches.keys.indexOf(batches.currentId!) < batches.keys.length - 1,
    [batches],
  );

  const onPrevBatchClick = useCallback(() => {
    setBatches(prev => {
      const nextIndex = max([0, prev.keys.indexOf(prev.currentId!) - 1]);
      const nextCurrentId = prev.keys[nextIndex!];
      return {
        ...prev,
        currentId: nextCurrentId,
      };
    });
  }, []);

  const handleNextBatchClick = useCallback(() => {
    setBatches(prev => {
      const nextIndex = min([prev.keys.length - 1, prev.keys.indexOf(prev.currentId!) + 1]);
      const nextCurrentId = prev.keys[nextIndex!];
      return {
        ...prev,
        currentId: nextCurrentId,
      };
    });
  }, []);

  const handleNewBatchClick = useCallback(() => {
    newBatch();
  }, []);

  const currentBatchName = useMemo(() => {
    const batch = batches.map[batches.currentId!];
    const endsAt = batch?.endsAt;
    if (!endsAt) return 'Recording ...';

    const endDate = new Date(endsAt);
    return endDate.toLocaleString() + '.' + endDate.getMilliseconds();
  }, [batches]);

  const isFirstBatch = useMemo(() => {
    const { keys, currentId } = batches;
    return keys[0] === currentId;
  }, [batches]);

  const [isSettingOpen, setIsSettingOpen] = useState(false);
  const handleSettingModalDismiss = useCallback(() => {
    setIsSettingOpen(false);
  }, []);
  const handleSettingOpenClick = useCallback(() => {
    setIsSettingOpen(true);
  }, []);

  const lastSyncedTime = useMemo(() => {
    const lastSyncNumericTime = min([recogListQuery.dataUpdatedAt, attendeesQuery.dataUpdatedAt]);
    return lastSyncNumericTime ? new Date(lastSyncNumericTime) : undefined;
  }, [recogListQuery.dataUpdatedAt, attendeesQuery.dataUpdatedAt]);

  return (
    <IonPage id="main-content">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>NDJC e-Recog</IonTitle>
          <IonButtons slot="end">
            <IonLabel>
              Last sync: {lastSyncedTime ? lastSyncedTime.toLocaleString() : 'Never'}
            </IonLabel>
            <IonButton color="primary" onClick={handleSettingOpenClick}>
              <IonIcon icon={settingsOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          {recogRecordNames.map(name => {
            const record = recogRecordMap[name];
            const noteState = record?.stateNoteState;
            const currentBatch = batches.map[batches.currentId!];
            if (!currentBatch) return null;

            const isCurrentBatchNote = record?.stateNoteBatchId === currentBatch.id;
            const checkedInTime = recogRecordMap[name]?.checkedInTime;
            const rangeStarts = isFirstBatch ? -Infinity : currentBatch.startsAt;
            const rangeEnds = currentBatch.endsAt ?? Infinity;
            const isCurrentBatchCheckIn =
              checkedInTime && isWithinTimeRange(checkedInTime, rangeStarts, rangeEnds);

            return (
              <IonItem key={name}>
                <IonLabel style={{ fontSize: 18 }}>{name}</IonLabel>
                <div slot="end">
                  <IonButton color="light" onClick={onStateNoteToggleClickHandlers[name]}>
                    {noteState === undefined ? (
                      <IonIcon icon={ellipseOutline} size="large" color="medium" />
                    ) : noteState ? (
                      <IonIcon
                        icon={checkmarkCircleOutline}
                        size="large"
                        color={isCurrentBatchNote ? 'success' : 'medium'}
                      />
                    ) : (
                      <IonIcon
                        icon={closeCircleOutline}
                        size="large"
                        color={isCurrentBatchNote ? 'danger' : 'medium'}
                      />
                    )}
                  </IonButton>
                </div>
                <IonIcon
                  icon={checkedInTime ? checkmarkCircle : ellipseOutline}
                  slot="end"
                  color={isCurrentBatchCheckIn ? 'success' : 'medium'}
                  size="large"
                  style={{ opacity: 1 }}
                />
              </IonItem>
            );
          })}
        </IonList>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButton
            size="large"
            color="light"
            slot="start"
            disabled={!hasPrevBatch}
            onClick={onPrevBatchClick}
          >
            <IonIcon icon={chevronBackOutline} />
          </IonButton>
          <IonButton size="large" color="light" expand="block">
            {currentBatchName}
          </IonButton>
          {hasNextBatch ? (
            <IonButton size="large" color="light" slot="end" onClick={handleNextBatchClick}>
              <IonIcon icon={chevronForwardOutline} />
            </IonButton>
          ) : (
            <IonButton size="large" color="secondary" slot="end" onClick={handleNewBatchClick}>
              <IonIcon icon={addIcon} />
            </IonButton>
          )}
        </IonToolbar>
      </IonFooter>
      <SettingModal isOpened={isSettingOpen} onDismiss={handleSettingModalDismiss} />
    </IonPage>
  );
}

export default App;
